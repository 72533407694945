
<template>
  <v-container fluid>
    <new-course></new-course>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'NewCoursePage',
  components: {
    NewCourse: () => import('@/components/courses/create/NewCourse.vue'),
  },
  data() {
    return {
      
    }
  },
  methods: {
    ...mapActions({
      
    })
  },
  computed: {
    ...mapState({
      
    })
  },
  watch: {
    
  },
}
</script>

<style scoped>

</style>